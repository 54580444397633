import React, { useMemo } from 'react';
import { HardwarePerformanceT } from '../../interfaces/dashboard';
import { Image } from 'antd';
import Text from '../Text';
import TotalProductImg from '../../images/DashboardProduct.png';
import TooltipIcon from '../buttons/TooltipIcon';
import HorizonBarItem from './HorizonBarItem';
import EmptyData from '../EmptyData';
import { convertLiterToMl } from '../../utils/global';

type Props = {
  data?: HardwarePerformanceT['productList'];
  maxHeightContainer?: number;
  totalProductInEcoToteTooltip: string;
  productbyCurrentVolume: string;
};

const HardwareTotalProductInEcoTote: React.FC<Props> = ({
  data,
  maxHeightContainer,
  totalProductInEcoToteTooltip,
  productbyCurrentVolume,
}) => {
  const maxVolume = useMemo(() => {
    if (data?.data && data?.data?.length > 0) {
      return data?.data.reduce((max, curr) => (curr.volume > max ? curr.volume : max), data?.data[0].volume ?? 0) ?? 0;
    }
    return 0;
  }, [data]);

  const sortedData = useMemo(() => {
    return data?.data?.sort((a, b) => b.volume - a.volume);
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg sm:tw-p-4">
      <div className="tw-flex tw-items-start tw-gap-6 tw-mb-6">
        <div className="tw-flex-1 tw-flex tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-border-grey-300 tw-p-3">
          <Image src={TotalProductImg} preview={false} />
          <div className="tw-flex tw-flex-col">
            <Text variant="h4" className="tw-text-grey-600 tw-flex tw-gap-1 tw-items-center">
              Total Products in ecoTOTE <TooltipIcon text={totalProductInEcoToteTooltip} />
            </Text>
            <Text variant="h1">{(data?.totalProducts ?? 0).toLocaleString()}</Text>
          </div>
        </div>
      </div>
      <div className="tw-bg-grey-50 tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg tw-mt-10 sm:tw-p-4">
        <span className="tw-flex tw-items-center tw-mb-5 tw-gap-2">
          <Text variant="inputLabelMd" className="tw-text-grey-700 tw-flex tw-gap-1 tw-items-center">
            Products by Current Volume <TooltipIcon text={productbyCurrentVolume} />
          </Text>
        </span>
        <div className="tw-overflow-auto tw-pr-3 tw-pr-3" style={{ minHeight: maxHeightContainer ?? '150px' }}>
          {sortedData && sortedData.length > 0 ? (
            sortedData?.map((product) => {
              return (
                <HorizonBarItem
                  key={product?.sku}
                  title={product?.name}
                  valueText={`${convertLiterToMl(product?.volume)} L`}
                  itemTotalValue={((product?.volume ?? 0) / maxVolume) * 100}
                  maxValue={maxVolume * 100}
                  itemValue={((product?.volume ?? 0) / maxVolume) * 100}
                  isBackground={false}
                  labelGap={138}
                  barColor="#00538D"
                />
              );
            })
          ) : (
            <EmptyData message="No Product in ecoTOTE" imageSize={{ width: '180px', height: '200px' }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HardwareTotalProductInEcoTote;
