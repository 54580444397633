import React, { useMemo } from 'react';
import ECharts from '../charts/ECharts';
import Text from '../Text';
import { ProductPerformanceI } from '../../interfaces/dashboard';
import { Image, Spin } from 'antd';
import { EChartsOption } from 'echarts';
import BottleImg from '../../images/Bottle.png';
import CarbonImg from '../../images/Co2.png';
import TooltipIcon from '../buttons/TooltipIcon';
import { convertLiterToMl, convertStringtoDate } from '../../utils/global';
import { DateRangeT } from '../../interfaces';
import { mapDataForDateRange } from '../../utils/dashboard';
import dayjs from 'dayjs';

type Props = {
  data?: ProductPerformanceI['sustainability'];
  isLoading: boolean;
  activeDateRange: DateRangeT;
  packagingWasteEliminated: string;
  productCO2eAvoided: string;
};

const ProductSustainability: React.FC<Props> = ({
  data,
  isLoading,
  activeDateRange,
  packagingWasteEliminated,
  productCO2eAvoided,
}) => {
  const pieChartOption: EChartsOption = useMemo(() => {
    const mappedData = mapDataForDateRange(data?.data ?? {}, activeDateRange);
    let getXAxisData: string[] = [];
    let seriesData1: (string | null)[] = [];
    let seriesData2: (string | null)[] = [];

    if (
      activeDateRange.alias === 'thisyear' ||
      (activeDateRange.alias === 'custom' &&
        dayjs(activeDateRange.period.until).diff(dayjs(activeDateRange.period.since), 'day') > 31)
    ) {
      const monthlyData1: { [key: string]: number } = {};
      const monthlyData2: { [key: string]: number } = {};
      Object.entries(mappedData).forEach(([date, value]) => {
        const newDate = dayjs(date, 'DD-MM-YYYY');
        const month = dayjs(newDate).format('MMM');

        if (month !== null && !monthlyData1[month]) {
          monthlyData1[month] = 0;
        }
        if (month !== null && !monthlyData2[month]) {
          monthlyData2[month] = 0;
        }
        if (month !== null && value !== null) {
          monthlyData1[month] += value[0];
          monthlyData2[month] += value[1];
        }
      });
      getXAxisData = Object.keys(monthlyData1);
      seriesData1 = Object.values(monthlyData1).map((value) => (value === 0 ? null : convertLiterToMl(value)));
      seriesData2 = Object.values(monthlyData2).map((value) => (value === 0 ? null : convertLiterToMl(value)));
    } else {
      getXAxisData = Object.keys(mappedData)
        .map((item) => convertStringtoDate(item))
        .filter((item): item is string => item !== null);
      seriesData1 = Object.values(mappedData).map((value) => (value ? convertLiterToMl(value[0]) : null));
      seriesData2 = Object.values(mappedData).map((value) => (value ? convertLiterToMl(value[1]) : null));
    }

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: getXAxisData,
        axisLine: {
          lineStyle: {
            color: '#000',
          },
        },
        axisLabel: {
          color: '#666',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#ccc',
          },
        },
        axisLabel: {
          color: '#666',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        {
          name: 'Packaging Waste Eliminated',
          type: 'bar',
          data: seriesData1,
          itemStyle: {
            color: '#00538D',
          },
          label: {
            show: false,
          },
          barGap: '0%',
          barMaxWidth: 30,
        },
        {
          name: 'CO2 Avoided',
          type: 'bar',
          data: seriesData2,
          itemStyle: {
            color: '#94C482',
          },
          label: {
            show: false,
          },
          barGap: '0%',
          barMaxWidth: 30,
        },
      ],
    };
  }, [data, activeDateRange]);

  return (
    <div className="sm:tw-px-0 sm:tw-rounded-none">
      <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 tw-mt-6 sm:tw-p-4 sm:tw-mt-4">
        <Spin spinning={isLoading}>
          <Text component="h2" variant="h2" className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800">
            Sustainability
            {/* <Icon name="icon-chevron_right" /> */}
          </Text>
          <div className="tw-flex tw-items-start tw-h-full tw-gap-6 tw-mt-2 tw-mb-6 sm:tw-flex-col">
            <div className="tw-flex-1 tw-flex tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-border-grey-300 tw-p-3 tw-w-full">
              <Image src={BottleImg} preview={false} />
              <div className="tw-flex tw-flex-col">
                <Text variant="h4" className="tw-text-grey-600 tw-flex tw-gap-1 tw-items-center">
                  Packaging Waste Eliminated (kg) <TooltipIcon text={packagingWasteEliminated} />
                </Text>
                <Text variant="h1">{convertLiterToMl(data?.totalPackagingWasteEliminated)}</Text>
              </div>
            </div>
            <div className="tw-flex-1 tw-flex tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-border-grey-300 tw-p-3 tw-w-full">
              <Image src={CarbonImg} preview={false} />
              <div className="tw-flex tw-flex-col">
                <Text variant="h4" className="tw-text-grey-600 tw-flex tw-gap-1 tw-items-center">
                  CO2 e Avoided (kg) <TooltipIcon text={productCO2eAvoided} />
                </Text>
                <Text variant="h1">{convertLiterToMl(data?.totalCo2Avoided)}</Text>
              </div>
            </div>
          </div>
          <div>
            <ECharts option={pieChartOption} style={{ width: '100%', height: '500px', flex: 'auto' }} />
            <div className="tw-flex tw-gap-9 tw-justify-center sm:tw-gap-3">
              <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
                <span className="tw-min-w-4 tw-h-4 tw-bg-secondary-600 tw-rounded-[50%] tw-inline-block" />{' '}
                <Text variant="inputLabelSm">Packaging Waste Eliminated</Text>
              </span>
              <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
                <span className="tw-min-w-4 tw-h-4 tw-bg-primary-400 tw-rounded-[50%] tw-inline-block" />{' '}
                <Text variant="inputLabelSm">CO2 Emission Avoided</Text>
              </span>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ProductSustainability;
