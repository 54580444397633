import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../utils/global';
import { ProductGroupI, ProductI } from '../../interfaces/product';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: ProductGroupI[];
};

const ProductGroupsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Group name',
      width: 200,
      ellipsis: true,
      render: (record: ProductGroupI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-x-2">
            <span>{`${record?.title}`}</span>
            {/* {record?.remarks !== null && <Icon name="icon-text_snippet" size={16} />} */}
          </span>
        );
      },
    },
    {
      title: 'Group code',
      dataIndex: 'code',
      width: 150,
      ellipsis: true,
      render: (brandName: string) => {
        return <span>{brandName}</span>;
      },
    },
    {
      title: 'no. of products',
      dataIndex: 'products',
      width: 150,
      ellipsis: true,
      render: (products: ProductI[]) => {
        return <span>{products?.length ?? 0}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ProductGroupsTable;
