import { Divider, Input, Modal, Spin } from 'antd';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAppNotification } from '../hooks/useAppNotification';
import Text from './Text';
import { Notice } from './Notice';
import { Link, useNavigate } from 'react-router-dom';
import Icon from './Icon';
import SearchInputIcon from '../svgs/SearchInputIcon';
import { ConfigurationI } from '../interfaces/configuration';
import { doApplyHardwareConfiguration, getConfigurationsList } from '../apis/configurations';
import EmptyData from './EmptyData';
import { IconName } from './Icon/iconName';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../utils/hardware';
import { DateFormat } from '../utils/global';
import SecondaryButton from './buttons/SecondaryButton';
import ConfirmButton from './buttons/ConfirmButton';
import { HardwareI } from '../interfaces/hardware';

const NoticeContentRenter = () => {
  return (
    <>
      <Link to="/">
        Edit Hardware Configuration <Icon name="icon-chevron_right" />
      </Link>
    </>
  );
};

const HardwareApplyConfigModal = ({
  hardware,
  open,
  setOpen,
  setCurrentTab,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hardware: HardwareI;
  setCurrentTab?: (_: string) => void;
}) => {
  const [filteredData, setFilteredData] = useState<ConfigurationI[]>([]);
  const [configuration, setConfiguration] = useState<ConfigurationI>();
  const { openNotification } = useAppNotification();
  const navigate = useNavigate();

  const { data: { hardwareConfigs = [] } = {}, isLoading } = useQuery(
    ['configurations', 'list', {}],
    () => getConfigurationsList(),
    { enabled: open },
  );

  const handleCancel = () => {
    setOpen(false);
    setConfiguration(undefined);
  };

  const { mutate, isLoading: isApplyLoading } = useMutation(doApplyHardwareConfiguration, {
    onSuccess: () => {
      setOpen(false);
      openNotification({
        type: 'success',
        title: 'Successfully applied configuration preset!',
        content: <Text variant="bodyMd">Configuration preset has been applied to this hardware.</Text>,
      });
      navigate(`#tracking-resources`);
      setCurrentTab?.('5');
    },
    onError: () => {
      openNotification({
        type: 'error',
        title: 'Could not applied configuration preset!',
        content: <Text variant="bodyMd">Please try it again!</Text>,
      });
    },
  });

  const handleApply = () => {
    mutate({ serialCode: hardware.serialCode, hardwareConfigCode: configuration?.code ?? '' });
  };

  const onFilterChange = (event: any) => {
    const { value } = event.target;
    if (value) {
      const data = hardwareConfigs.filter(
        (item: ConfigurationI) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.code.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredData(data);
    } else {
      setFilteredData(hardwareConfigs);
    }
  };

  useEffect(() => {
    if (hardwareConfigs.length) {
      setFilteredData(hardwareConfigs);
    }
  }, [hardwareConfigs]);

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Text variant="h2" className="tw-p-4 tw-pb-0 tw-my-0">
          Apply a configuration preset
        </Text>
      }
      footer={null}
      width={580}
    >
      <Spin spinning={isApplyLoading}>
        <Text variant="bodyLg" className="tw-mb-4">
          Apply an existing configuration preset’s settings to this hardware
        </Text>
        <Notice
          type="info"
          message="You can also manually edit this hardware’s configuration. "
          description={<NoticeContentRenter />}
          closable={false}
        />
        <Input
          placeholder="Search for a site or address"
          prefix={<SearchInputIcon />}
          onChange={onFilterChange}
          className="tw-mt-4"
        />
        <Spin spinning={isLoading}>
          <div className="card-list tw-mt-5 tw-h-[30vh] tw-overflow-y-auto tw-justify-center">
            {!isLoading && filteredData?.length === 0 && (
              <EmptyData
                title="No Configuration Preset"
                message="To apply a configuration to this hardware, you can create a new configuration preset first"
                button={{
                  title: 'Create a preset',
                  url: `/configurations/new?type=${hardware?.type}`,
                }}
              />
            )}
            {filteredData?.map((item: ConfigurationI) => {
              return (
                <button
                  className={`${configuration?.id === item.id ? 'active !tw-bg-primary-50' : 'non-active'} card-item hardware-card-item tw-max-h-[188px] tw-bg-white tw-rounded-lg tw-border-none tw-w-full tw-mb-3 tw-flex tw-flex-col tw-py-4 tw-px-4 tw-gap-1 hover:tw-bg-primary-50`}
                  key={item.id}
                  onClick={() => setConfiguration(item)}
                >
                  <div className="item">
                    <Text variant="inputLabelSm" className="tw-text-grey-700">
                      Configuration Preset
                    </Text>
                    <div className="tw-flex tw-flex-col tw-w-full tw-gap-4">
                      <Text variant="bodyLgB" className="tw-text-primary-800 tw-mt-2">
                        {item.name}
                      </Text>
                      <div className="tw-flex tw-flex-col tw-w-full tw-gap-2">
                        {renderField({ icon: 'icon-build_circle', value: item?.code })}
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
                          {handleDisplayIconByTypeFromApi(item?.hardwareType, {
                            width: 16,
                            height: 16,
                          })}
                          <Text variant="inputLabelSm" className="flex-1">
                            {getTypeFromApi(item)}
                          </Text>
                        </div>
                      </div>
                      <Divider className="tw-m-0" />
                      {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </Spin>
        <div>
          <ConfirmButton
            label="Apply"
            type="info"
            className="tw-w-full tw-mb-3 tw-mt-4 tw-bg-primary-500 tw-text-white hover:!tw-bg-primary-500 hover:!tw-text-white disabled:hover:!tw-bg-grey-50 disabled:hover:!tw-text-grey-500"
            onOk={handleApply}
            width={497}
            content={
              <>
                <h2>Proceed to apply configuration to hardware?</h2>
                <p>
                  Upon confirmation, configuration{' '}
                  <span className="tw-font-bold">{`${configuration?.name} - ${configuration?.code}`}</span> will be
                  applied to this hardware.{' '}
                </p>
              </>
            }
            disabled={!configuration}
          />
          <SecondaryButton label="Cancel" className="tw-w-full" onClick={handleCancel} />
        </div>
      </Spin>
    </Modal>
  );
};

export default HardwareApplyConfigModal;
