import React from 'react';
import { ProductGroupI } from '../../interfaces/product';
import DetailsView from '../DetailsView';
import DisplayQRCode from '../shared/DisplayQRCode';
import { DateFormat } from '../../utils/global';
import Divider from '../Divider';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, message } from 'antd';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  data: ProductGroupI;
};

const ProductGroupInformation: React.FC<Props> = ({ data }) => {
  return (
    <div className="details-container">
      <DetailsView title="Product Group Details">
        <div className="flex-3-column">
          <DetailsView.Field
            label="Product Group Code"
            value={<DisplayQRCode data={data?.code} downloadName={`${data?.code}.png`} />}
          />
          <DetailsView.Field
            label="Product Group Code"
            value={
              <span className="tw-flex tw-items-center tw-gap-2">
                <Text variant="bodyLgB">{data?.code}</Text>
                <CopyToClipboard text={data?.code ?? ''} onCopy={() => message.info('Copied')}>
                  <Button className="tw-border-none tw-p-0">
                    <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                  </Button>
                </CopyToClipboard>
              </span>
            }
          />
        </div>
        <Divider />
        <DetailsView.Field label="Date Created" value={DateFormat(data?.createdAt)} />
        <Divider />
        <DetailsView.Field label="Number of Products Within Group" value={data?.products.length ?? 0} />
        <Divider />
        <DetailsView.Field label="Remarks" value={data?.remarks} />
      </DetailsView>
    </div>
  );
};

export default ProductGroupInformation;
