import React, { useState } from 'react';
import { HardwareRegisterParamsI, HardwareScannedStateI, HardwareVerifyCollectionI } from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, Spin, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { convertToApiFile } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import { handleGetUniqType } from '../../utils/hardware';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import Icon from '../Icon';
import Text from '../Text';
import TransferSuccessModal from './SuccessModal';
import { PartnerI } from '../../interfaces/partner';
import DetailsView from '../DetailsView';
import SizeBox from '../SizeBox';
import { doTransfer } from '../../apis/transfers';
import EAnalytics from '../../analytics';

type Props = {
  verifiedData?: HardwareVerifyCollectionI;
  successData: any;
  previousStep?: number;
  partner?: PartnerI;
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  setSuccessData: (_: any) => void;
  setFinalScannedData: (_: HardwareScannedStateI[]) => void;
  setPartner: (_: PartnerI | undefined) => void;
};

const TransferReviewStep: React.FC<Props> = ({
  successData,
  verifiedData,
  previousStep,
  partner,
  setCurrentStep,
  setScannedData,
  setSuccessData,
  setFinalScannedData,
  setPartner,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const ConfirmContent = () => {
    return (
      <div>
        <h2>Proceed with Ownership Tranfer?</h2>
        <Text variant="bodyLg" className="!tw-inline-table">
          Upon confirmation, Valid Scanned Hardware will be transferred to{' '}
          <Text variant="bodyLgB" className="tw-text-grey-800 tw-inline">
            {partner?.displayName}
          </Text>{' '}
          ownership.
        </Text>
        <SizeBox height={24} />
        <Text variant="bodyLg" className="danger-text" component="p">
          Note
        </Text>
        <ul className="tw-pl-[20px] tw-m-0">
          <li className="danger-text">
            <Text variant="bodyLg" className="danger-text tw-inline">
              All transferred hardware will be removed from your Tracking and you will no longer view the hardware,
              unless they are still allocated at your Site(s).
            </Text>
          </li>
        </ul>
      </div>
    );
  };

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: doCreateMutate, isLoading: isCreateLoading } = useMutation(doTransfer, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries();
      setIsSuccessModal(true);
      setScannedData([]);
      setFinalScannedData([]);
      setPartner(undefined);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: HardwareRegisterParamsI) => {
    const newValues: any = values;
    const hardwareList = verifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      delete item.type;
      return item;
    });

    newValues.hardwareList = hardwareList;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.newOwnerId = partner?.id;
    doCreateMutate(newValues);
    EAnalytics.trackScanComplete('transfer');
  };

  return (
    <Spin spinning={!verifiedData || isCreateLoading}>
      <div className="tw-max-w-[680px] tw-my-0 tw-mx-auto">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
          className="tw-mb-[100px]"
        >
          <div className="" style={{ marginTop: 16 }}>
            <DetailsView title="New Hardware Owner’s Information">
              <DetailsView.Field label="Transferred to Partner:" value={partner?.displayName} />
            </DetailsView>
            <SizeBox height={16} />
            <DetailsView title="Ownership Transfer Details">
              <DetailsView.Field label="Total Number of Hardware" value={verifiedData?.hardwareList?.length ?? 0} />
              <DetailsView.Field
                label="Hardware Type"
                value={handleGetUniqType(verifiedData?.hardwareList ?? []).map(
                  (item: { type: string; count: number }) => (
                    <>
                      {item.type.includes('ecoTOTE') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd">{item.count}</Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('ecoPLANT') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd">{item.count}</Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('SmartPour') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd">{item.count}</Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                    </>
                  ),
                )}
              />
              <Divider />
              <div className="details-content-wrap">
                <div className="form-container">
                  <div className="form-content">
                    <Form.Item label="Remarks" name="remarks">
                      <Input.TextArea placeholder="Type remarks" rows={5} />
                    </Form.Item>
                    <p className="label">Media Attachments</p>
                    <UploadAttachmentButton
                      categories={AttachmentCategories}
                      form={form}
                      setFiles={setFiles}
                      files={files}
                    />
                  </div>
                </div>
              </div>
            </DetailsView>
          </div>
        </Form>
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(previousStep ?? 2)} />
          <ConfirmButton
            type="info"
            label="Finish Scanning"
            onOk={() => form.submit()}
            content={<ConfirmContent />}
            closable={false}
            width={570}
          />
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel}>
        <TransferSuccessModal data={successData} />
      </NonClosableModal>
    </Spin>
  );
};

export default TransferReviewStep;
