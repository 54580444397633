import React, { useState } from 'react';
import { Form, Input, message, Modal, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useAppNotification } from '../hooks/useAppNotification';
import { resolveFaultReport } from '../apis/faultReports';
import Text from './Text';
import { convertToApiFile } from '../utils/global';
import { Notice } from './Notice';
import UploadAttachmentButton from './buttons/UploadAttachmentButton';
import { FaultCategories } from '../constants';
import Divider from './Divider';
import SecondaryButton from './buttons/SecondaryButton';
import PrimaryButton from './buttons/PrimaryButton';
import { AttachmentI } from '../interfaces/attachments';

type FormI = {
  resolutionComment: string;
  attachments: AttachmentI[];
};

type Props = {
  data?: any;
  setIsModalVisible: (value: boolean) => void;
  isModalVisible: boolean;
};

const FaultyResolveModal: React.FC<Props> = ({ data, setIsModalVisible, isModalVisible }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const queryClient = useQueryClient();
  const { openNotification } = useAppNotification();

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFiles([]);
  };

  const { mutate: createMutate, isLoading } = useMutation(resolveFaultReport, {
    onSuccess: async (successData) => {
      openNotification({
        type: 'success',
        title: 'Hardware Fault Resolved!',
        content: (
          <Text variant="bodyMd">
            Fault report <span className="tw-font-bold">{successData?.code}</span> has been resolved. The condition of
            hardware <span className="tw-font-bold">{data?.serialCode}</span> has been be updated to{' '}
            <span className="tw-text-primary-500">Good</span>.
          </Text>
        ),
      });
      queryClient.invalidateQueries();
      handleCancel();
    },
    onError: () => {
      message.error('Your action failed. Please try again!');
      handleCancel();
    },
  });

  const handleSubmit = (values: FormI) => {
    const newValues: FormI = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    createMutate({ code: data.code, values: newValues });
  };

  return (
    <div onKeyDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={588}
        title={<Text variant="h2">Proceed to resolve this fault report?</Text>}
        className="fault-report-button-container"
      >
        <Spin spinning={isLoading}>
          <Notice
            type="warning"
            description={
              <span>
                Upon confirmation of this fault resolution, the condition {data?.serialCode} will be changed to{' '}
                <span className="tw-text-primary-500"> Good.</span>
              </span>
            }
            closable={false}
          />
          <Form
            data-testid="faulty-resolve-modal"
            form={form}
            onFinish={handleSubmit}
            scrollToFirstError
            autoComplete="off"
            layout={'vertical'}
          >
            <div className="form-container">
              <div className="form-content">
                <Form.Item
                  name="resolutionComment"
                  label="Resolution Comment"
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <Input.TextArea placeholder="Type Resolution Comment" rows={5} />
                </Form.Item>
                <Form.Item name="attachments" label="Attachments">
                  <UploadAttachmentButton categories={FaultCategories} form={form} setFiles={setFiles} files={files} />
                </Form.Item>
                <Divider />
                <div className="">
                  <PrimaryButton
                    label="Confirm"
                    className="tw-w-full tw-mb-3 tw-mt-4 tw-bg-primary-500 tw-text-white hover:!tw-bg-primary-500 hover:!tw-text-white"
                    htmlType="submit"
                  />
                  <SecondaryButton label={'Cancel'} onClick={handleCancel} className="tw-w-full" />
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default FaultyResolveModal;
