import { Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { SiteI } from '../../interfaces/site';
import Text from '../Text';
import { getAddress, humanizeText } from '../../utils/global';
import './SiteSelector.scss';
import { DashboardSearchParamsI } from '../../interfaces/dashboard';
import useWindowResize from '../../hooks/useWindowResize';

type Props = {
  sites: SiteI[];
  isSiteLoading?: boolean;
  className?: string;
  queryParams: DashboardSearchParamsI & { siteId: number; siteType: string };
  handleSiteChange: (value: string) => void;
};

const SiteSelector: React.FC<Props> = ({ sites, isSiteLoading, className, queryParams, handleSiteChange }) => {
  const { width } = useWindowResize();
  const [form] = Form.useForm();

  useEffect(() => {
    if (queryParams.siteId) {
      form.setFieldsValue({ site: queryParams.siteId });
    } else {
      form.setFieldsValue({ site: sites[0]?.id });
    }
  }, [queryParams, sites]);

  return (
    <>
      <Form form={form} requiredMark={false} colon={false} scrollToFirstError className="tw-w-full" layout="vertical">
        <Form.Item name="site" label={width < 767 ? '' : 'Site'}>
          <Select
            onChange={handleSiteChange}
            placeholder="All Sites"
            showSearch
            optionFilterProp="children"
            className={`site-selector tw-p-0 tw-w-full !tw-mb-0 ${className}`}
            loading={isSiteLoading}
            optionLabelProp="label"
            rootClassName="site-selector-root"
            allowClear
          >
            {sites.map((site: SiteI) => (
              <Select.Option
                key={site.id}
                value={site.id}
                label={
                  <Text variant="bodyMdB" className="tw-text-grey-700 tw-inline">
                    <span className="tw-text-grey-900">{`${site.displayName}`}</span>{' '}
                    <span className="text-capitalize">{`[${humanizeText(site.type)}]`}</span>
                  </Text>
                }
              >
                <span className="tw-flex tw-gap-1 tw-flex-col">
                  <Text variant="bodyMdB" className="tw-text-grey-700">
                    <span className="tw-text-grey-900">{`${site.displayName}`}</span>{' '}
                    <span className="text-capitalize">{`[${humanizeText(site.type)}]`}</span>
                  </Text>
                  <Text variant="bodySm" className="tw-text-grey-700">
                    {getAddress(site)}
                  </Text>
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default SiteSelector;
