import React, { useState } from 'react';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import { Button, Drawer } from 'antd';
import EmptyData from '../EmptyData';
import NoticeIcon from '../../svgs/NoticeIcon';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  scannedData: HardwareScannedStateI[];
};

const TransferScanTabsContent: React.FC<Props> = ({ scannedData }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
      <div className="tw-flex tw-gap-1 tw-mb-4">
        <span className="tw-mt-0.5">
          <NoticeIcon />
        </span>
        <div>
          <span>QR/Barcode can be found on:</span>
          <ul className="tw-my-0.5 tw-pl-6">
            <li>outer casing of ecoTOTEs</li>
            <li>underside of SmartPour 1.1S, on the inside of the SmartPour 2.0S rear door</li>
            <li>area near the display screen of the Filling Machine in the ecoPLANT</li>
          </ul>
        </div>
      </div>
      <div className="scanned-btn tw-mb-6">
        <Button
          type="primary"
          onClick={showDrawer}
          className="tw-bg-white tw-text-grey-900 hover:!tw-text-grey-900 hover:!tw-bg-white tw-shadow-none tw-border tw-rounded-lg tw-border-grey-300"
        >
          <Icon name="icon-all_inbox" size={24} className="tw-text-grey-700" />
          {scannedData?.length} Code(s) Scanned
          <Icon name="icon-expand_more" size={24} />
        </Button>
      </div>
      <Drawer
        title={<span>{`${scannedData.length} Code(s) Scanned`}</span>}
        onClose={onDrawerClose}
        open={open}
        rootClassName="hardware-scan-qrcode-drawer"
      >
        <div className="hardware-scanned-list-drawer">
          {scannedData?.length > 0 ? (
            scannedData.map((data: HardwareScannedStateI) => (
              <div key={data.serialCode} className="item">
                <Text variant="bodyLgB" className="tw-text-primary-900 !tw-block">{`${data.serialCode}`}</Text>
              </div>
            ))
          ) : (
            <EmptyData message="No hardware scanned yet" />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default TransferScanTabsContent;
