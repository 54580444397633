import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { DashboardSearchParamsI } from '../../interfaces/dashboard';
import { convertDayJSToApi, findDateRangeOption, handleFilterParams } from '../../utils/global';
import { Notice } from '../../components/Notice';
import Text from '../../components/Text';
import RefreshButton from '../../components/buttons/RefreshButton';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import {
  dateRangeOptions,
  SiteEmptyTooltip,
  SiteFilledTooltip,
  SiteHardwareTypeTooltip,
  SitePartiallyTooltip,
  SiteTotalEcoToteTooltip,
  SiteTotalHardwareTooltip,
  SiteTotalProductInEcoToteTooltip,
  SiteTotalProductionTooltip,
  SiteTotalConsumedTooltip,
  SiteProductbyCurrentVolume,
  SitePackagingWasteEliminated,
  SiteCO2eAvoided,
  SiteTotalReceivedVolumeTooltip,
} from '../../constants/dashboard';
import { useGetDashboardSiteFilter, useGetDashboardSitePerformance } from '../../apis/dashboard';
import { getHardwareSitesList } from '../../apis/hardwares';
import { useQuery } from 'react-query';
import DashboardProductFilter from '../../components/forms/dashboard/ProductFilter';
import DashboardProductionVolume from '../../components/Dashboard/ProductionVolume';
import HardwareFleet from '../../components/Dashboard/HardwareFleet';
import SiteSelector from '../../components/forms/SiteSelector';
import ProductSustainability from '../../components/Dashboard/ProductSustainability';
import DashboardTotalConsumedValue from '../../components/Dashboard/TotalConsumedValue';
import HardwareEcoToteStatus from '../../components/Dashboard/EcoToteStatus';
import HardwareTotalProductInEcoTote from '../../components/Dashboard/TotalProductInEcotote';
import { SiteTypeT } from '../../interfaces/site';
import { DateRangeT } from '../../interfaces';
import { TrackDashboardEventI } from '../../analytics/models';
import { CurrentUserI } from '../../interfaces/auth';
import EAnalytics from '../../analytics';
import dayjs from 'dayjs';
import useWindowResize from '../../hooks/useWindowResize';
import { Spin } from 'antd';

const SitePerformance: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location?.search]);
  const { width } = useWindowResize();

  const queryParams = useMemo(() => {
    const startDate = urlSearch.get('startDate');
    const endDate = urlSearch.get('endDate');
    const country = urlSearch.get('country')?.split(',');
    const product = urlSearch.get('product')?.split(',');
    const siteId = urlSearch.get('siteId') ? Number(urlSearch.get('siteId')) : undefined;
    const siteType = urlSearch.get('siteType') ?? '';

    return {
      startDate,
      endDate,
      country,
      product,
      siteId,
      siteType,
    };
  }, [urlSearch]) as DashboardSearchParamsI & { siteId: number; siteType: string };

  useEffect(() => {
    const event: TrackDashboardEventI = {
      page: 'Site Performance',
      timestamp: new Date().toISOString(),
      partnerOwner: currentUser.username,
      payload: {
        ...queryParams,
      },
    };

    EAnalytics.trackDashboard(event);
  }, []);

  const [selectedSite, setSelectedSite] = useState<string>();
  const [siteType, setSiteType] = useState<SiteTypeT | undefined>();
  const dateRangeQuery = findDateRangeOption(
    queryParams?.startDate ?? '',
    queryParams?.endDate ?? '',
    dateRangeOptions,
  );

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const [activeDateRange, setActiveDateRange] = useState<DateRangeT>(dateRangeQuery ?? dateRangeOptions[0]);
  const { data, isLoading, refetch } = useGetDashboardSitePerformance(queryParams);
  const { data: filterData } = useGetDashboardSiteFilter(queryParams);
  const { data: { sites = [] } = {}, isLoading: isSiteLoading } = useQuery(
    ['sites', 'list', {}],
    () => getHardwareSitesList({}),
    {
      select({ data: { sites } }) {
        return { sites };
      },
    },
  );

  const setQueryParams = (params: DashboardSearchParamsI & { siteId?: number; siteType?: string }) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  useEffect(() => {
    if (sites.length > 0 && (!queryParams.siteId || !queryParams.startDate || !queryParams.endDate)) {
      setQueryParams({
        ...queryParams,
        startDate: convertDayJSToApi(dayjs(activeDateRange?.period.since)),
        endDate: convertDayJSToApi(dayjs(activeDateRange?.period.until)),
        siteId: sites[0].id,
        siteType: sites[0].type,
      });
    }
  }, [sites]);

  useEffect(() => {
    setSelectedSite(queryParams.siteId?.toString());
    if (queryParams.siteType) {
      setSiteType(queryParams.siteType as SiteTypeT);
    } else if (!queryParams.siteType && queryParams.siteId) {
      const site = sites.find((site) => site.id === queryParams.siteId);
      setSiteType(site?.type);
    }
  }, [queryParams, sites]);

  const handleSiteChange = (value: string) => {
    const type = sites.find((site) => site.id === Number(value))?.type;
    setQueryParams({ ...queryParams, siteId: Number(value), siteType: type });
  };

  return (
    <Spin spinning={isRefetchLoading ?? isLoading}>
      <div className="tw-mt-6">
        {width > 768 && (
          <>
            <div className="tw-flex tw-items-center tw-justify-center tw-px-4">
              <Notice
                type="info"
                message={
                  <Text variant="bodyMd" className="tw-text-grey-900">
                    <span className="tw-font-bold">Site Performance</span> offers insights into the performance of
                    individual sites managed by your partnership.
                  </Text>
                }
                closable={false}
                iconSize={16}
                className="tw-mt-2 tw-mb-3 tw-flex-auto tw-mr-4 tw-w-full"
              />
              <RefreshButton
                doRefetch={refetch}
                className="tw-flex-auto !tw-w-12 !tw-h-12"
                setIsLoading={setIsRefetchLoading}
              />
            </div>
            <div className="tw-flex tw-justify-end sm:tw-mb-4 tw-px-4">
              <DisplayLastUpdateApp />
            </div>
          </>
        )}
        {width < 768 && (
          <>
            <div className="tw-flex tw-justify-between tw-items-center xs:tw-flex-col xs:tw-items-start tw-mb-4 tw-px-4">
              <DisplayLastUpdateApp />
              <RefreshButton
                doRefetch={refetch}
                className="xs:tw-w-full xs:tw-mt-4"
                setIsLoading={setIsRefetchLoading}
              />
            </div>
          </>
        )}
        <div className="tw-px-4">
          <SiteSelector
            sites={sites}
            isSiteLoading={isSiteLoading}
            handleSiteChange={handleSiteChange}
            className="tw-mb-6"
            queryParams={queryParams}
          />
        </div>
        <div className="tw-px-4 sm:tw-px-0">
          <div className="tw-bg-white tw-rounded-lg tw-border-primary tw-p-6 sm:tw-p-4 sm:tw-rounded-none">
            <DashboardProductFilter
              setQueryParams={setQueryParams}
              setActiveDateRange={setActiveDateRange}
              activeDateRange={activeDateRange}
              dateRangeOptions={dateRangeOptions}
              filterData={filterData}
              queryParams={queryParams}
              type="site"
            />
            <div
              className={`tw-mt-6 tw-gap-6 tw-grid ${siteType === 'consume' ? 'tw-grid-cols-2 sm:tw-grid-cols-1' : 'tw-grid-cols-1'}`}
            >
              <DashboardProductionVolume
                data={siteType === 'filling' ? data?.productionVolume : data?.receivedVolume}
                isLoading={isLoading}
                activeDateRange={activeDateRange}
                siteType={siteType}
                totalProductionTooltip={
                  siteType === 'filling' ? SiteTotalProductionTooltip : SiteTotalReceivedVolumeTooltip
                }
              />
              {siteType === 'consume' && (
                <DashboardTotalConsumedValue
                  data={data?.consumedVolume}
                  isLoading={isLoading}
                  activeDateRange={activeDateRange}
                  totalConsumedTooltip={SiteTotalConsumedTooltip}
                />
              )}
            </div>
            {siteType === 'consume' && (
              <div className="tw-mt-6">
                <ProductSustainability
                  data={data?.sustainability}
                  isLoading={isLoading}
                  activeDateRange={activeDateRange}
                  packagingWasteEliminated={SitePackagingWasteEliminated}
                  productCO2eAvoided={SiteCO2eAvoided}
                />
              </div>
            )}
          </div>
        </div>
        <div className="tw-px-4 sm:tw-px-0 sm:tw-rounded-none">
          <div className="tw-bg-white tw-rounded-lg tw-border-primary tw-p-6 tw-mt-6 sm:tw-p-4 sm:tw-mt-4">
            <HardwareFleet
              data={data?.hardwareFleet}
              isLoading={isLoading}
              totalHardwareTooltip={SiteTotalHardwareTooltip}
              hardwareTypeTooltip={SiteHardwareTypeTooltip}
            />
            <Text variant="h3" className="tw-text-grey-700 tw-mt-10">
              ecoTOTE Current Status
            </Text>
            <div className="tw-mt-3 tw-gap-6 tw-grid tw-grid-cols-2 sm:tw-grid-cols-1">
              <HardwareEcoToteStatus
                data={data?.ecoToteStatus}
                isLoading={isLoading}
                siteId={Number(selectedSite)}
                dashboardType="site"
                totalEcoToteTooltip={SiteTotalEcoToteTooltip}
                filledTooltip={SiteFilledTooltip}
                partiallyTooltip={SitePartiallyTooltip}
                emptyTooltip={SiteEmptyTooltip}
              />
              <HardwareTotalProductInEcoTote
                data={data?.productList}
                maxHeightContainer={250}
                totalProductInEcoToteTooltip={SiteTotalProductInEcoToteTooltip}
                productbyCurrentVolume={SiteProductbyCurrentVolume}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SitePerformance;
