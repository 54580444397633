import React, { useMemo, useState } from 'react';
import ECharts from '../charts/ECharts';
import Text from '../Text';
import { ProductPerformanceI } from '../../interfaces/dashboard';
import { Spin } from 'antd';
import { EChartsOption } from 'echarts';
import dayjs from 'dayjs';
import { DateRangeT } from '../../interfaces';
import TooltipIcon from '../buttons/TooltipIcon';
import { convertStringtoDate } from '../../utils/global';
import DetailsCard from './DetailsCard';
import HorizonBarItem from './HorizonBarItem';
import { mapCountryData } from '../../utils/dashboard';

type Props = {
  data?: ProductPerformanceI['productOutlet'];
  isLoading: boolean;
  activeDateRange: DateRangeT;
  totalOutletTooltip: string;
  outletTransactionTooltip: string;
  outletWithoutTransactionTooltip: string;
  totalOutletByCountryTooltip: string;
};

const ProductTotalOutlet: React.FC<Props> = ({
  data,
  isLoading,
  activeDateRange,
  totalOutletTooltip,
  outletTransactionTooltip,
  outletWithoutTransactionTooltip,
  totalOutletByCountryTooltip,
}) => {
  const [maxSite, setMaxSite] = useState<number>(0);
  const countrySites = useMemo(() => {
    const mappedData = mapCountryData(data?.countrySites ?? []).sort((a, b) => b.totalSite - a.totalSite);
    const maxSiteCountry = mappedData.reduce(
      (max, country) => (country.totalSite > max.totalSite ? country : max),
      mappedData[0],
    );
    setMaxSite(maxSiteCountry?.totalSite);
    return mappedData;
  }, [data]);

  const transactionPercentage = useMemo(() => {
    return data && data.totalOutlets > 0 ? (data.totalOutletWithTransactions / data.totalOutlets) * 100 : 0;
  }, [data]);

  const pieChartOption: EChartsOption = useMemo(() => {
    const totalOutlets = data?.totalOutlets ?? 0;
    const totalOutletWithTransactions = data?.totalOutletWithTransactions ?? 0;
    const remainingOutlets = totalOutlets - totalOutletWithTransactions;

    return {
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: 'Outlets',
          type: 'pie',
          radius: ['60%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: ['{a|Total Outlets}', '{span|All-time}', '{tooltipIcon|}', '{b|' + totalOutlets + '}'].join(
              '\n',
            ),
            rich: {
              a: {
                color: '#898989',
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 500,
                fontFamily: 'Poppins',
              },
              span: {
                color: '#898989',
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 400,
                fontFamily: 'Poppins',
              },
              b: {
                color: '#191919',
                fontFamily: 'Poppins',
                fontSize: 34,
                fontWeight: 500,
                lineHeight: 42,
                letterSpacing: '-0.68px',
                marginTop: 12,
              },
              tooltipIcon: {
                height: 20,
                width: 20,
                align: 'center',
                marginBottom: 12,
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: totalOutletWithTransactions, name: 'With Transactions', itemStyle: { color: '#94C482' } },
            { value: remainingOutlets, name: 'Without Transactions', itemStyle: { color: '#E3E3E3' } },
          ],
        },
      ],
    };
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Spin spinning={isLoading}>
        <Text component="h2" variant="h2" className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800">
          Total Outlet
          {/* <Icon name="icon-chevron_right" /> */}
        </Text>
        <div className="tw-mt-6 tw-gap-6 tw-grid tw-grid-cols-2 sm:tw-grid-cols-1">
          <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap sm:tw-justify-center">
            <div className="tw-relative tw-w-full tw-h-[260px] tw-max-w-[260px] sm:tw-max-w-full">
              <div className="tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center">
                <span className="tw-z-10 tw-h-5">
                  <TooltipIcon text={totalOutletTooltip} iconSize={20} buttonClassName="tw-w-5 !tw-h-5" />
                </span>
              </div>
              <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
                <ECharts option={pieChartOption} style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
            <div className="sm:tw-w-full tw-flex-1 tw-min-w-[150px] lg:tw-max-w-[200px]">
              <DetailsCard
                period={`${convertStringtoDate(dayjs(activeDateRange.period.since).format('DD-MM-YYYY'))} - Today`}
                tooltipText={outletTransactionTooltip}
                percentage={transactionPercentage.toFixed(0)}
                title="Outlet with Transaction"
                color="#94C482"
                totalNumber={data?.totalOutletWithTransactions ?? 0}
              />
              <DetailsCard
                period={`${convertStringtoDate(dayjs(activeDateRange.period.since).format('DD-MM-YYYY'))} - Today`}
                tooltipText={outletWithoutTransactionTooltip}
                percentage={
                  transactionPercentage === 0
                    ? data?.totalOutlets === 0
                      ? 0
                      : 100
                    : (100 - transactionPercentage).toFixed(0)
                }
                title="Outlet without Transaction"
                color="#E3E3E3"
                totalNumber={(data?.totalOutlets ?? 0) - (data?.totalOutletWithTransactions ?? 0)}
              />
            </div>
          </div>
          <div className="tw-bg-grey-50 tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg">
            <span className="tw-flex tw-items-center tw-mb-5 tw-gap-2">
              <Text variant="inputLabelMd" className="tw-text-grey-700 tw-block">
                Total Outlet by Country{' '}
              </Text>
              <TooltipIcon text={totalOutletByCountryTooltip} />
            </span>
            <div className="tw-h-[150px] tw-overflow-auto tw-pr-3">
              {countrySites.map((country) => {
                const maxLength = (country.totalSite / maxSite) * 100;
                const transactionLength = (country.siteWithTransaction / country.totalSite) * 100;
                return (
                  <HorizonBarItem
                    key={country.countryCode}
                    title={country.countryName}
                    icon={country.countryFlag}
                    valueText={country.totalSite}
                    itemTotalValue={maxLength}
                    maxValue={maxSite}
                    itemValue={transactionLength}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ProductTotalOutlet;
