import React from 'react';
import LoginForm from '../../components/forms/LoginForm';
import './Login.scss';
import { doGetUserProfile, doLogin } from '../../apis/auth';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CurrentUserI, LoginParamsI } from '../../interfaces/auth';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../../constants';
import EAnalytics from '../../analytics';
import { message } from 'antd';

const LoginPage: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleGetProfile = async (values: LoginParamsI) => {
    try {
      const response: CurrentUserI = await doGetUserProfile();
      const trackingParams = {
        emailOrUsername: values.emailUsername,
        userType: response?.type,
        partnerId: response?.partnerId,
        userId: response?.id,
      };
      EAnalytics.trackLogin(trackingParams);
    } catch (error) {
      message.error('Failed to get user profile');
    }
  };

  const handleSubmit = async (values: LoginParamsI) => {
    try {
      const { data }: AxiosResponse = await doLogin(values);
      const authData = JSON.stringify(data?.data);
      Cookies.set(TOKEN_NAME, authData);
      queryClient.invalidateQueries();
      handleGetProfile(values);
      if (data?.data?.authToken) navigate('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="auth-container">
      <LoginForm handleSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;
