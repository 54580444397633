import React, { useEffect, useState } from 'react';
import { HardwareProgressStepListI } from '../../../interfaces';
import {
  HardwareGroupTypeI,
  HardwareItemI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
} from '../../../interfaces/hardware';
import HardwareVerifyFilterForm from '../../forms/hardware/VerifyFilter';
import SharedHardwareProgressBar from './ProgressBar';
import { doVerifyHardwareGroup } from '../../../apis/hardwares';
import useNavigateAway from '../../../hooks/useNavigateAway';
import HardwareGroupingReviewStep from '../../Hardware/GroupingReviewStep';
import { getEcototeVolumn, handleRemoveVerifiedData } from '../../../utils/hardware';
import HardwareRegisterScanStep from '../../Hardware/ScanStep';
import W2WHardwareGroupVerifyStep from './W2WGroupVerifyStep';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../../interfaces/site';
import EAnalytics from '../../../analytics';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Choose Type',
    disabled: true,
  },
  {
    step: 2,
    label: 'Site',
    disabled: true,
  },
  {
    step: 3,
    label: 'Scan',
  },
  {
    step: 4,
    label: 'Verify',
  },
  {
    step: 5,
    label: 'Review',
  },
];

type Props = {
  successData: any;
  registerType: HardwareTypeI;
  siteId?: number;
  backTo?: string;
  site?: SiteI;
};

const HardwareW2WGroupingPage: React.FC<Props> = ({ successData, registerType, siteId, backTo = '/', site }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(4);
  const [registerData, setRegisterData] = useState<HardwareScannedStateI[]>([]);
  const [hardwareVerifiedData, setHardwareVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);

  const handleVerify = async (hardwareList: HardwareScannedStateI[]) => {
    try {
      const finalData = [...finalScannedData, ...hardwareList];
      const params = {
        hardwareList: finalData,
        hardwareType: registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l' ? 'ecotote' : registerType,
        toteVolume:
          registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l'
            ? getEcototeVolumn(registerType)
            : undefined,
        siteId,
      } as {
        hardwareList: HardwareScannedStateI[];
        hardwareType: HardwareGroupTypeI;
        toteVolume: string;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setRegisterData([]);
      const data = await doVerifyHardwareGroup(params);
      setHardwareVerifiedData(data);
      setCurrentStep(4);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyScanned = async () => {
    try {
      const finalData = [...finalScannedData, ...registerData];
      const params = {
        hardwareList: finalData,
        hardwareType: registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l' ? 'ecotote' : registerType,
        toteVolume:
          registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l'
            ? getEcototeVolumn(registerType)
            : undefined,
        siteId,
      } as {
        hardwareList: HardwareScannedStateI[];
        hardwareType: HardwareGroupTypeI;
        toteVolume: string;
        siteId: number;
      };
      const ignoreGroup = finalData.filter((item) => !item?.serialCode?.startsWith('HGP'));
      setFinalScannedData(ignoreGroup);
      setRegisterData([]);
      const data = await doVerifyHardwareGroup(params);
      setHardwareVerifiedData(data);
      setCurrentStep(4);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = hardwareVerifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...hardwareVerifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(hardwareVerifiedData, item.serialCode);
    setHardwareVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  useNavigateAway({
    when: finalScannedData.length > 0 || registerData.length > 0,
    handleProceed() {
      setRegisterData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
    },
  });

  useEffect(() => {
    if (successData) {
      const data = successData.hardwareList.map((item: any) => {
        return {
          serialCode: item.serialCode,
        };
      });
      handleVerify(data);
    }
  }, [successData]);

  const handleScanmoreButton = () => {
    setCurrentStep(3);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 3) setCurrentStep(currentStep - 1);
    if (currentStep === 3) navigate(backTo);
  };

  useEffect(() => {
    const step = progressStepList.find((item) => item.step === currentStep) || { label: '' };
    EAnalytics.trackScanWorkflow(step?.label);
  }, [currentStep]);

  return (
    <div id="pages-hardwares-registrations-new-container">
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Hardware Grouping"
        stepList={progressStepList}
        backFunction={backFunction}
      />
      {currentStep === 4 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={hardwareVerifiedData?.numberOfHardwareScanned}
          numberOfInvalid={hardwareVerifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 3 && (
        <HardwareRegisterScanStep
          setCurrentStep={setCurrentStep}
          setRegisterData={setRegisterData}
          registerData={registerData}
          finalScannedData={finalScannedData}
          setFinalScannedData={setFinalScannedData}
          registerType={registerType}
          handleVerify={handleVerifyScanned}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          setVerifiedData={setHardwareVerifiedData}
        />
      )}
      {currentStep === 4 && (
        <W2WHardwareGroupVerifyStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={filtered ?? hardwareVerifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
        />
      )}
      {currentStep === 5 && (
        <HardwareGroupingReviewStep
          setCurrentStep={setCurrentStep}
          registerType={registerType}
          registerVerifiedData={hardwareVerifiedData}
          setRegisterData={setRegisterData}
          setFinalScannedData={setFinalScannedData}
          siteId={siteId}
          site={site}
        />
      )}
    </div>
  );
};

export default HardwareW2WGroupingPage;
