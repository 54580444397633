import React, { useMemo } from 'react';
import ECharts from '../charts/ECharts';
import { EChartsOption } from 'echarts';
import Text from '../Text';
import Icon from '../Icon';
import { DashboardTotalConsumedT } from '../../interfaces/dashboard';
import TooltipIcon from '../buttons/TooltipIcon';
import { Spin } from 'antd';
import { DateRangeT } from '../../interfaces';
import { mapDataForDateRange } from '../../utils/dashboard';
import dayjs from 'dayjs';
import { convertLiterToMl, convertStringtoDate } from '../../utils/global';

type Props = {
  data?: DashboardTotalConsumedT;
  isLoading: boolean;
  activeDateRange: DateRangeT;
  totalConsumedTooltip: string;
};

const DashboardTotalConsumedValue: React.FC<Props> = ({ data, isLoading, activeDateRange, totalConsumedTooltip }) => {
  const barChartOption: EChartsOption = useMemo(() => {
    const mappedData = mapDataForDateRange(data?.data ?? {}, activeDateRange);
    let getXAxisData: string[] = [];
    let seriesData1: (string | null)[] = [];
    let seriesData2: (string | null)[] = [];
    let seriesData3: (string | null)[] = [];

    if (
      activeDateRange.alias === 'thisyear' ||
      (activeDateRange.alias === 'custom' &&
        dayjs(activeDateRange.period.until).diff(dayjs(activeDateRange.period.since), 'day') > 31)
    ) {
      const monthlyData1: { [key: string]: number } = {};
      const monthlyData2: { [key: string]: number } = {};
      const monthlyData3: { [key: string]: number } = {};
      Object.entries(mappedData).forEach(([date, value]) => {
        const newDate = dayjs(date, 'DD-MM-YYYY');
        const month = dayjs(newDate).format('MMM');

        if (month !== null && !monthlyData1[month]) {
          monthlyData1[month] = 0;
        }
        if (month !== null && !monthlyData2[month]) {
          monthlyData2[month] = 0;
        }
        if (month !== null && !monthlyData3[month]) {
          monthlyData3[month] = 0;
        }
        if (month !== null && value !== null) {
          monthlyData1[month] += value[0];
          monthlyData2[month] += value[1];
          monthlyData3[month] += value[2];
        }
      });
      getXAxisData = Object.keys(monthlyData1);
      seriesData1 = Object.values(monthlyData1).map((value) => convertLiterToMl(value));
      seriesData2 = Object.values(monthlyData2).map((value) => convertLiterToMl(value));
      seriesData3 = Object.values(monthlyData3).map((value) => convertLiterToMl(value));
    } else {
      getXAxisData = Object.keys(mappedData)
        .map((item) => convertStringtoDate(item))
        .filter((item): item is string => item !== null);
      seriesData1 = Object.values(mappedData).map((value) => (value ? convertLiterToMl(value[0]) : null));
      seriesData2 = Object.values(mappedData).map((value) => (value ? convertLiterToMl(value[1]) : null));
      seriesData3 = Object.values(mappedData).map((value) => (value ? convertLiterToMl(value[2]) : null));
    }

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: getXAxisData,
        axisLine: {
          lineStyle: {
            color: '#000',
          },
        },
        axisLabel: {
          color: '#666',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#ccc',
          },
        },
        axisLabel: {
          color: '#666',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        {
          name: 'Marked Deplete',
          type: 'bar',
          stack: 'total',
          data: seriesData1,
          itemStyle: {
            color: '#004474',
          },
          barWidth: 20,
        },
        {
          name: 'Dispense',
          type: 'bar',
          stack: 'total',
          data: seriesData2,
          itemStyle: {
            color: '#3AA5DA',
          },
          barWidth: 20,
        },
        {
          name: 'Prime',
          type: 'bar',
          stack: 'total',
          data: seriesData3,
          itemStyle: {
            color: '#ADE7FF',
          },
          barWidth: 20,
        },
      ],
    };
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Spin spinning={isLoading}>
        <Text component="h2" variant="h2" className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800">
          Total Consumed Volume
          {/* <Icon name="icon-chevron_right" /> */}
        </Text>
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center">
            <Icon name="icon-water-drop" size={40} className="tw-text-secondary-500" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                Total Consumed (L)
              </Text>{' '}
              <TooltipIcon text={totalConsumedTooltip} />
            </div>
            <Text variant="h1">{convertLiterToMl(data?.totalConsumed)}</Text>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap">
          <ECharts option={barChartOption} style={{ width: '100%', height: '400px' }} />
          <div className="tw-flex tw-gap-9 tw-justify-center tw-w-full xs:tw-gap-3">
            <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
              <span className="tw-min-w-4 tw-h-4 tw-bg-secondary-700 tw-rounded-[50%] tw-inline-block" />{' '}
              <Text variant="inputLabelSm">Marked Deplete</Text>
            </span>
            <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
              <span className="tw-min-w-4 tw-h-4 tw-bg-secondary-300 tw-rounded-[50%] tw-inline-block" />{' '}
              <Text variant="inputLabelSm">Dispense</Text>
            </span>
            <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
              <span className="tw-min-w-4 tw-h-4 tw-bg-secondary-100 tw-rounded-[50%] tw-inline-block" />{' '}
              <Text variant="inputLabelSm">Prime</Text>
            </span>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardTotalConsumedValue;
