import React, { useMemo, useState } from 'react';
import Text from '../Text';
import Icon from '../Icon';
import { HardwarePerformanceT } from '../../interfaces/dashboard';
import TooltipIcon from '../buttons/TooltipIcon';
import { Spin } from 'antd';
import { Countries } from 'hoangdevtrai-country-list-js';
import * as Flags from 'country-flag-icons/react/3x2';
import HorizonBarItem from './HorizonBarItem';

const mapCountryData = (data: HardwarePerformanceT['ecoToteUsage']['ecoToteWithInteractionsByCountry']) => {
  return data.map((item) => {
    const country = Countries.find((c) => c.value === item.country);
    const CountryFlag = Flags[item.country as keyof typeof Flags];
    return {
      ...item,
      countryName: country ? country.label : 'Unknown',
      countryFlag: CountryFlag ? <CountryFlag title={item.country} className="country-flag tw-w-5" /> : null,
    };
  });
};
type Props = {
  data?: HardwarePerformanceT['ecoToteUsage'];
  isLoading: boolean;
  ecoToteInteractionTooltip: string;
  hardwareEcoToteInteractionsByCountryTooltip: string;
};

const EcoToteUsage: React.FC<Props> = ({
  data,
  isLoading,
  ecoToteInteractionTooltip,
  hardwareEcoToteInteractionsByCountryTooltip,
}) => {
  const [maxValue, setMaxValue] = useState<number>(0);
  const countrySites = useMemo(() => {
    const mappedData = mapCountryData(data?.ecoToteWithInteractionsByCountry ?? []).sort(
      (a, b) => b.totalEcoTote - a.totalEcoTote,
    );
    const maxValueCountry = mappedData.reduce(
      (max, country) => (country.totalEcoTote > max.totalEcoTote ? country : max),
      mappedData[0],
    );
    setMaxValue(maxValueCountry?.totalEcoTote ?? 0);
    return mappedData;
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Spin spinning={isLoading}>
        <Text component="h2" variant="h2" className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800">
          ecoTOTE Usage
          {/* <Icon name="icon-chevron_right" /> */}
        </Text>
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center">
            <Icon name="icon-eco-tote" size={40} className="tw-text-primary-600" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                ecoTOTE with Interactions
              </Text>{' '}
              <TooltipIcon text={ecoToteInteractionTooltip} />
            </div>
            <Text variant="h1">{(data?.ecoToteWithInteractions ?? 0).toLocaleString()}</Text>
          </div>
        </div>
        <div className="tw-bg-grey-50 tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg tw-mt-10">
          <span className="tw-flex tw-items-center tw-mb-5 tw-gap-2">
            <Text variant="inputLabelMd" className="tw-text-grey-700 tw-block">
              ecoTOTE with Interactions by Country{' '}
            </Text>
            <TooltipIcon text={hardwareEcoToteInteractionsByCountryTooltip} />
          </span>
          <div className="tw-h-[100px] tw-overflow-auto tw-pr-3">
            {countrySites.map((country) => {
              const maxLength = (country.totalEcoTote / maxValue) * 100;
              const availableValue = (country.totalEcoTote / maxValue) * 100;
              return (
                <HorizonBarItem
                  key={country.country}
                  title={country.countryName}
                  icon={country.countryFlag}
                  valueText={country.totalEcoTote}
                  itemTotalValue={maxLength}
                  maxValue={maxValue}
                  itemValue={availableValue}
                />
              );
            })}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default EcoToteUsage;
