import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { addEmailListEmail } from '../../apis/emailLists';
import { useParams } from 'react-router-dom';
import { handleFilterParams } from '../../utils/global';
import { EmailListDetailsSearchParamsI } from '../../interfaces/emailList';
import { PAGE_SIZE } from '../../constants';
import Text from '../../components/Text';

const AddNewEmailModal = ({
  addNewEmailModalOpen,
  setAddNewEmailModalOpen,
}: {
  addNewEmailModalOpen: boolean;
  setAddNewEmailModalOpen: (value: boolean) => void;
}) => {
  const handleCancel = () => {
    setAddNewEmailModalOpen(false);
  };
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { id } = useParams();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const name = urlSearch.get('search[name]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      viewMode,
      search: name,
    } as EmailListDetailsSearchParamsI & { viewMode?: string };
  }, [urlSearch]);
  const { mutate, isLoading } = useMutation(
    (formData: { username: string; email: string }) => addEmailListEmail(+id!, formData),
    {
      mutationKey: [`email-list/${id}`],
      onSuccess: () => {
        setAddNewEmailModalOpen(false);
        form.resetFields();
        queryClient.invalidateQueries([`email-list/${id}?${handleFilterParams(queryParams)}`]);
      },
    },
  );
  const handleSubmit = async (values: { username: string; email: string }) => {
    mutate(values);
  };
  return (
    <Modal open={addNewEmailModalOpen} onCancel={handleCancel} footer={null} width={673}>
      <Spin spinning={isLoading}>
        <Text variant="h2">Add New Email</Text>
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <div className="form-container">
            <div className="form-content">
              <Form.Item label="Name" name="username" rules={[{ required: true, message: 'This field is required' }]}>
                <Input type="text" placeholder="Type here" />
              </Form.Item>
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'This field is required' }]}>
                <Input type="text" placeholder="Type here" />
              </Form.Item>
              <span className="tw-flex tw-flex-row tw-w-full tw-gap-4">
                <Button type="default" className="tw-text-primary-500 tw-border-primary-500 tw-flex-grow tw-w-full">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" className="tw-flex-grow tw-w-full">
                  Add
                </Button>
              </span>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddNewEmailModal;
