import { Button, Drawer, Form, Input, message, Select, Spin, Switch, Tag } from 'antd';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Icon from './Icon';
import { PhoneNumberInput } from './PhoneNumberInput/PhoneNumberInput';
import { convertToApiFile, getCountryFromCode, humanizeText } from '../utils/global';
import { AttachmentCategories, EightAlphanumericCharactersRegex, Markets, SiteTypes } from '../constants';
import countryList from 'react-select-country-list';
import PrimaryButton from './buttons/PrimaryButton';
import UploadAttachmentButton from './buttons/UploadAttachmentButton';
import DetailsView from './DetailsView';
import { PartnerI } from '../interfaces/partner';
import { useMutation, useQueryClient } from 'react-query';
import { requestPartnerCreation } from '../apis/partners';
import randomstring from 'randomstring';
import FileDefault from '../images/file-default.png';
import SharedProgressBar from './shared/ProgressBar';
import { useAppNotification } from '../hooks/useAppNotification';
import Text from './Text';

const stepList = [
  {
    step: 1,
    label: 'Partner Info',
  },
  {
    step: 2,
    label: 'First Site Info',
  },
  {
    step: 3,
    label: 'Review',
  },
];

const PartnerCreationRequestDrawer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState<any[]>([]);
  const [createSite, setCreateSite] = useState(false);
  const [form] = Form.useForm();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { openNotification } = useAppNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(requestPartnerCreation, {
    onSuccess: async () => {
      setOpen(false);
      setCurrentPage(1);
      form.resetFields();
      queryClient.invalidateQueries();
      openNotification({
        type: 'success',
        title: 'Partner Creation Request Sent!',
        content: (
          <Text variant="bodyMd">
            ecoSPIRITS team will review your request in 1 working day. We will contact you via your email to notify the
            status of the request.
          </Text>
        ),
      });
    },
    onError: () => {
      message.error('Create partner failed! Please try again');
    },
  });

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setFiles([]);
    setCreateSite(false);
    setCurrentPage(1);
  };

  const handleOnFinish = () => {
    const newValues: PartnerI = form.getFieldsValue(true);
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    mutate(newValues);
  };

  return (
    <div>
      <Drawer
        onClose={handleCancel}
        open={open}
        closeIcon={false}
        width={681}
        styles={{ body: { padding: 0 } }}
        rootClassName="drawer-has-title-width-full"
        title={
          <div className="tw-font-normal">
            <span className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-16">
              <p className="tw-h-fit tw-m-0 tw-text-4xl">Partner Creation Request</p>
              <button onClick={handleCancel} className="tw-border-none tw-bg-white">
                <Icon name="icon-close" className="tw-cursor-pointer hover:tw-opacity-75" />
              </button>
            </span>
            <SharedProgressBar currentStep={currentPage} stepList={stepList} />
          </div>
        }
        footer={
          <>
            {currentPage === 3 && (
              <p className="tw-text-sm" style={{ color: '#898989', margin: '1rem 0 2rem 0' }}>
                Once submitted, this form will require an approval from the ecoSPIRITS team. Once approved, the newly
                added Partner will get an email verification to verify their account. *Kindly give ecoSPIRITS team 1
                working day to review. We will contact you via your email to notify the status of the request.
              </p>
            )}
            <span className="tw-flex tw-flex-row tw-gap-4">
              {currentPage !== 1 && (
                <Button
                  className="tw-flex-grow-2 tw-w-1/4"
                  onClick={() => {
                    if (currentPage === 1) {
                      return;
                    }
                    setCurrentPage((prevPage) => prevPage - 1);
                  }}
                >
                  Back
                </Button>
              )}
              {currentPage === 1 && (
                <PrimaryButton
                  className="tw-flex-grow tw-w-3/4"
                  onClick={async () => {
                    try {
                      await form.validateFields([
                        'referenceCode',
                        'legalName',
                        'displayName',
                        'email',
                        'contactNumber',
                        'market',
                        'uploadFile',
                        'adminUsername',
                        'adminEmail',
                        'adminContactNumber',
                        'addressLine1',
                        'addressLine2',
                        'addressLine3',
                        'country',
                        'region',
                        'state',
                        'postalCode',
                      ]);
                    } catch (e) {
                      return;
                    }
                    setCurrentPage((prevPage) => prevPage + 1);
                  }}
                  label="Continue"
                />
              )}
              {currentPage === 2 && (
                <PrimaryButton
                  className="tw-flex-grow tw-w-3/4"
                  onClick={async () => {
                    try {
                      await form.validateFields([
                        ['defaultSite', 'legalName'],
                        ['defaultSite', 'displayName'],
                        ['defaultSite', 'market'],
                        ['defaultSite', 'type'],
                        ['defaultSite', 'addressLine1'],
                        ['defaultSite', 'country'],
                        ['defaultSite', 'postalCode'],
                      ]);
                    } catch (e) {
                      return;
                    }
                    setCurrentPage((prevPage) => prevPage + 1);
                  }}
                  label="Continue"
                />
              )}
              {currentPage === 3 && (
                <PrimaryButton
                  className="tw-flex-grow tw-w-3/4"
                  onClick={async () => {
                    try {
                      await form.validateFields([
                        'legalName',
                        'displayName',
                        'email',
                        'contactNumber',
                        'market',
                        'adminUsername',
                        'adminEmail',
                        'adminContactNumber',
                        'addressLine1',
                        'addressLine2',
                        'addressLine3',
                        'country',
                        'region',
                        'state',
                        'postalCode',
                      ]);
                      form.submit();
                    } catch (e) {
                      return;
                    }
                  }}
                  label="Submit Request"
                />
              )}
            </span>
          </>
        }
      >
        <div className="tw-p-8 tw-flex-grow tw-overflow-y-auto">
          <Spin spinning={isLoading}>
            <Form
              layout="vertical"
              className="form-container"
              form={form}
              scrollToFirstError
              onFinish={handleOnFinish}
              requiredMark
              initialValues={{ referenceCode: randomstring.generate({ length: 8 }) }}
            >
              <div className="form-container">
                <div className="form-content !tw-mt-0">
                  {currentPage === 1 && (
                    <>
                      <h2 className="form-title">Partner Information</h2>
                      <div>
                        <div className="flex-2-column">
                          <Form.Item
                            label="Reference Code"
                            name="referenceCode"
                            className="tw-min-w-[250px]"
                            rules={[
                              {
                                validator(_: any, value) {
                                  if (!EightAlphanumericCharactersRegex.test(value)) {
                                    return Promise.reject(new Error('This field should contain exactly 8 characters.'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <Form.Item
                            label="Business Legal Name"
                            name="legalName"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type here" />
                          </Form.Item>
                        </div>
                        <div className="flex-2-column">
                          <Form.Item
                            label="Business Name"
                            name="displayName"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <Form.Item
                            label="Business Contact Email"
                            name="email"
                            className="tw-min-w-[250px]"
                            rules={[
                              { type: 'email', message: 'Invalid Email' },
                              { required: true, message: 'This field is required' },
                            ]}
                          >
                            <Input type="email" placeholder="Type your email" autoComplete="off" />
                          </Form.Item>
                        </div>
                        <div className="flex-2-column">
                          <Form.Item
                            label="Business Contact Number"
                            name="contactNumber"
                            style={{ width: '100%' }}
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <PhoneNumberInput />
                          </Form.Item>
                          <Form.Item
                            label="Market"
                            name="market"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Select placeholder="Choose multiple options" style={{ width: '100%' }} mode="multiple">
                              {Markets.map((type: { name: string; value: string }) => (
                                <Select.Option key={type.value} value={type.value}>
                                  <span>{humanizeText(type.name)}</span>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="attachment-list-section">
                        <h2 className="form-title">Upload File</h2>
                        <div>
                          <Form.Item name="uploadFile" style={{ width: '100%' }}>
                            <UploadAttachmentButton
                              categories={AttachmentCategories}
                              form={form}
                              setFiles={setFiles}
                              files={files}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <h2 className="form-title">Administrative Contact</h2>
                      <Form.Item
                        label="Administrator Username"
                        name="adminUsername"
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <Input placeholder="Type here" />
                      </Form.Item>
                      <Form.Item
                        label="Administrative Email"
                        name="adminEmail"
                        rules={[
                          { type: 'email', message: 'Invalid Email' },
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input type="email" placeholder="Type here" autoComplete="off" />
                      </Form.Item>
                      <Form.Item
                        label="Administrative Contact Number"
                        name="adminContactNumber"
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <PhoneNumberInput />
                      </Form.Item>
                      <h2 className="form-title">Location</h2>
                      <div>
                        <div className="flex-2-column">
                          <Form.Item
                            label="Address Line 1"
                            name="addressLine1"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <Form.Item label="Address Line 2" name="addressLine2" className="tw-min-w-[250px]">
                            <Input placeholder="Type here" />
                          </Form.Item>
                        </div>
                        <div className="flex-2-column">
                          <Form.Item label="Address Line 3" name="addressLine3" className="tw-min-w-[250px]">
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <Form.Item
                            label="Country"
                            name="country"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Select
                              placeholder="Select a country"
                              showSearch
                              optionFilterProp="label"
                              options={countryOptions}
                            />
                          </Form.Item>
                        </div>
                        <div className="flex-2-column">
                          <Form.Item label="Region" name="region" className="tw-min-w-[250px]">
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <Form.Item label="State/Province" name="state" className="tw-min-w-[250px]">
                            <Input placeholder="Type here" />
                          </Form.Item>
                        </div>
                        <div className="flex-2-column">
                          <Form.Item
                            label="Postal/Zip Code"
                            name="postalCode"
                            className="tw-min-w-[250px]"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type here" />
                          </Form.Item>
                          <div></div>
                        </div>
                      </div>
                    </>
                  )}
                  {currentPage === 2 && (
                    <>
                      <div
                        className="tw tw-rounded-lg tw-flex tw-flex-row tw-items-center tw-justify-between"
                        style={{ background: '#F8F8F8', border: '1px solid #DAD9D9', padding: '24px' }}
                      >
                        <b>Create a new site for new partner (Optional)</b>
                        <Switch checked={createSite} onChange={(e) => setCreateSite(e)} />
                      </div>
                      {createSite && (
                        <>
                          <h2 className="form-title">First Site Information</h2>
                          <Form.Item
                            label="Site Name"
                            name={['defaultSite', 'legalName']}
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type site name" />
                          </Form.Item>
                          <Form.Item
                            label="Display Name"
                            name={['defaultSite', 'displayName']}
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <Input placeholder="Type display name" />
                          </Form.Item>
                          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '20px' }}>
                            <Form.Item
                              label="Type of Site"
                              name={['defaultSite', 'type']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Select placeholder="Select an option" style={{ width: '100%' }}>
                                {SiteTypes.map((type: { name: string; value: string }) => (
                                  <Select.Option key={type.value} value={type.value}>
                                    <span className="text-capitalize">{humanizeText(type.name)}</span>
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Market"
                              name={['defaultSite', 'market']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Select placeholder="Select an option" style={{ width: '100%' }}>
                                {Markets.map((type: { name: string; value: string }) => (
                                  <Select.Option key={type.value} value={type.value}>
                                    <span>{humanizeText(type.name)}</span>
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <Form.Item label="Description" name={['defaultSite', 'description']}>
                            <Input.TextArea placeholder="Type a description to describe this site" rows={3} />
                          </Form.Item>
                          <h2 className="form-title">Location</h2>
                          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '20px' }}>
                            <Form.Item
                              label="Address Line 1"
                              name={['defaultSite', 'addressLine1']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input placeholder="Type here" />
                            </Form.Item>
                            <Form.Item label="Address Line 2" name={['defaultSite', 'addressLine2']}>
                              <Input placeholder="Type here" />
                            </Form.Item>
                            <Form.Item label="Address Line 3" name={['defaultSite', 'addressLine3']}>
                              <Input placeholder="Type here" />
                            </Form.Item>
                            <Form.Item
                              label="Country"
                              name={['defaultSite', 'country']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Select
                                placeholder="Select a country"
                                showSearch
                                optionFilterProp="label"
                                options={countryOptions}
                              />
                            </Form.Item>
                            <Form.Item label="Region" name={['defaultSite', 'region']}>
                              <Input placeholder="Type here" />
                            </Form.Item>
                            <Form.Item label="State/Province" name={['defaultSite', 'state']}>
                              <Input placeholder="Type here" />
                            </Form.Item>
                            <Form.Item
                              label="Postal/Zip Code"
                              name={['defaultSite', 'postalCode']}
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Input placeholder="Type here" />
                            </Form.Item>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {currentPage === 3 && (
                    <>
                      <h2 className="form-title">Partner Information</h2>
                      <div>
                        <div className="flex-2-column tw-mb-4">
                          <DetailsView.Field
                            label="Reference Code"
                            value={form.getFieldValue('referenceCode')}
                            className="tw-min-w-[250px]"
                          />
                          <DetailsView.Field
                            label="Business Legal Name"
                            value={form.getFieldValue('legalName')}
                            className="tw-min-w-[250px]"
                          />
                        </div>
                        <div className="flex-2-column tw-mb-4">
                          <DetailsView.Field
                            label="Business Name"
                            value={form.getFieldValue('displayName')}
                            className="tw-min-w-[250px]"
                          />
                          <DetailsView.Field
                            label="Business Contact Email"
                            value={form.getFieldValue('email')}
                            className="tw-min-w-[250px]"
                          />
                        </div>
                        <div className="flex-2-column tw-mb-4">
                          <DetailsView.Field
                            label="Business Contact Number"
                            value={form.getFieldValue('contactNumber')}
                            className="tw-min-w-[250px]"
                          />
                          <DetailsView.Field
                            label="Market"
                            className="tw-min-w-[250px]"
                            value={
                              form.getFieldValue('market') && form.getFieldValue('market').length > 0
                                ? form.getFieldValue('market').map((m: string) => <Tag key={m}>{m}</Tag>)
                                : 'Not Applicable'
                            }
                          />
                        </div>
                        <div className="flex-2-column tw-mb-4">
                          {!!files.length && (
                            <DetailsView.Field
                              label="Media Attachment"
                              value={
                                <div className="tw-flex tw-flex-col tw-gap-2">
                                  {files?.map((file: any, index: number) => (
                                    <div
                                      className="document-item tw-rounded-lg tw-p-4 tw-px-6"
                                      style={{ background: '#F8F8F8', border: '1px solid #DAD9D9' }}
                                      key={file.id || file.uid || index}
                                    >
                                      <div className="document-info tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-center">
                                        {file.type.includes('image') ? (
                                          <img
                                            src={file?.url ? file.url : FileDefault}
                                            alt="attachment"
                                            style={{ width: '80px', borderRadius: '10px' }}
                                          />
                                        ) : (
                                          <img src={FileDefault} alt="attachment" />
                                        )}

                                        <div className="tw-flex tw-flex-col">
                                          <b>{file?.name}</b>
                                          <p className="tw-m-0 category-name">{`Category: ${file?.categoryName ?? ''}`}</p>
                                          <p className="tw-m-0">{`${file?.size ? (file?.size / 1024).toFixed(0) + 'KB' : ''}`}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }
                            />
                          )}
                        </div>
                      </div>
                      <h2 className="form-title">Administrative Contact</h2>
                      <div>
                        <div className="flex-2-column tw-mb-4">
                          <DetailsView.Field
                            label="Administrator Username"
                            value={form.getFieldValue('adminUsername')}
                            className="tw-min-w-[250px]"
                          />
                          <DetailsView.Field
                            label="Administrative Email"
                            value={form.getFieldValue('adminEmail')}
                            className="tw-min-w-[250px]"
                          />
                        </div>
                        <div className="flex-2-column tw-mb-4">
                          <DetailsView.Field
                            className="tw-min-w-[250px]"
                            label="Administrative Contact Email"
                            value={form.getFieldValue('adminContactNumber')}
                          />
                        </div>
                      </div>
                      <h2 className="form-title">Location</h2>
                      <div className="flex-2-column tw-mb-4">
                        <DetailsView.Field
                          label="Address Line 1"
                          value={form.getFieldValue('addressLine1')}
                          className="tw-min-w-[250px]"
                        />
                        <DetailsView.Field
                          label="Address Line 2"
                          value={form.getFieldValue('addressLine2')}
                          className="tw-min-w-[250px]"
                        />
                      </div>
                      <div className="flex-2-column tw-mb-4">
                        <DetailsView.Field
                          label="Address Line 3"
                          value={form.getFieldValue('addressLine3')}
                          className="tw-min-w-[250px]"
                        />
                        <DetailsView.Field
                          label="Country"
                          value={getCountryFromCode(form.getFieldValue('country') ?? '')}
                          className="tw-min-w-[250px]"
                        />
                      </div>
                      <div className="flex-2-column tw-mb-4">
                        <DetailsView.Field
                          label="Region"
                          value={form.getFieldValue('region')}
                          className="tw-min-w-[250px]"
                        />
                        <DetailsView.Field
                          label="State/Province"
                          value={form.getFieldValue('state')}
                          className="tw-min-w-[250px]"
                        />
                      </div>
                      <div className="flex-2-column tw-mb-4">
                        <DetailsView.Field
                          label="Postal/Zip Code"
                          value={form.getFieldValue('postalCode')}
                          className="tw-min-w-[250px]"
                        />
                      </div>
                      {createSite && (
                        <div
                          className="tw tw-rounded-lg"
                          style={{
                            background: '#F8F8F8',
                            border: '1px solid #DAD9D9',
                            padding: '12px 24px',
                            marginTop: '3rem',
                          }}
                        >
                          <h2 className="form-title">First Site Information</h2>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Site Name"
                              value={form.getFieldValue(['defaultSite', 'legalName'])}
                              className="tw-min-w-[250px]"
                            />
                            <DetailsView.Field
                              label="Display Name"
                              value={form.getFieldValue(['defaultSite', 'displayName'])}
                              className="tw-min-w-[250px]"
                            />
                          </div>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Type of Site"
                              value={form.getFieldValue(['defaultSite', 'type'])}
                              className="tw-min-w-[250px]"
                            />
                            <DetailsView.Field
                              label="Market"
                              value={<Tag>{form.getFieldValue(['defaultSite', 'market'])}</Tag>}
                              className="tw-min-w-[250px]"
                            />
                          </div>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Description"
                              className="tw-min-w-[250px]"
                              value={
                                <b className="tw-whitespace-pre-wrap">
                                  {form.getFieldValue(['defaultSite', 'description']) ?? (
                                    <Text variant="bodyLgB">Not Applicable</Text>
                                  )}
                                </b>
                              }
                            />
                          </div>
                          <h2 className="form-title">Location</h2>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Address Line 1"
                              className="tw-min-w-[250px]"
                              value={form.getFieldValue(['defaultSite', 'addressLine1'])}
                            />
                            <DetailsView.Field
                              label="Address Line 2"
                              className="tw-min-w-[250px]"
                              value={form.getFieldValue(['defaultSite', 'addressLine2'])}
                            />
                          </div>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Address Line 3"
                              className="tw-min-w-[250px]"
                              value={form.getFieldValue(['defaultSite', 'addressLine3'])}
                            />
                            <DetailsView.Field
                              label="Country"
                              value={getCountryFromCode(form.getFieldValue(['defaultSite', 'country']) ?? '')}
                              className="tw-min-w-[250px]"
                            />
                          </div>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Region"
                              value={form.getFieldValue(['defaultSite', 'region'])}
                              className="tw-min-w-[250px]"
                            />
                            <DetailsView.Field
                              label="State/Province"
                              value={form.getFieldValue(['defaultSite', 'state'])}
                              className="tw-min-w-[250px]"
                            />
                          </div>
                          <div className="flex-2-column tw-mb-4">
                            <DetailsView.Field
                              label="Postal/Zip Code"
                              value={form.getFieldValue(['defaultSite', 'postalCode'])}
                              className="tw-min-w-[250px]"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Form>
          </Spin>
        </div>
      </Drawer>
    </div>
  );
};

export default PartnerCreationRequestDrawer;
