import { useEffect, useRef, useState } from 'react';
import EAnalytics from '../analytics';

const usePageTimeTracker = (pageName: string) => {
  const startTimeRef = useRef<number | null>(null);
  const totalTimeRef = useRef(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      if (startTimeRef.current) {
        const elapsed = Date.now() - startTimeRef.current;
        totalTimeRef.current += elapsed;
        setElapsedTime(totalTimeRef.current);
      }
      startTimeRef.current = null;
    } else if (document.visibilityState === 'visible') {
      startTimeRef.current = Date.now();
    }
  };

  useEffect(() => {
    startTimeRef.current = Date.now();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (startTimeRef.current) {
        const elapsed = Date.now() - startTimeRef.current;
        totalTimeRef.current += elapsed;
        setElapsedTime(totalTimeRef.current);
      }
      EAnalytics.trackScan({ type: 'qr', count: 1, duration: totalTimeRef.current });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [pageName]);

  return elapsedTime;
};

export default usePageTimeTracker;
